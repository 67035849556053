export const WORDS = [
"Birdy",
"Birdy",
"Batch",
"Booth",
"Baked",
"Cakes",
"Jammy",
"Nyack",
"Goods",
"Berry",
"Peach",
"Lemon",
"Jelly",
"Apple",
"Sauce",
"Grape",
"Whisk",
"Spoon",
"Snaps",
"Share",
"Fruit",
"Homey",
"Yummy",
"Tasty",
"Sweet",
"Syrup",
"Pecan",
"Moist",
"Heart",
"Saras",
"Vinny",
"Cheer",
"Order",
"Gifts",
"Honey",
"Spice",
"Cocoa",
"Sugar",
"Scone",
"Crumb",
"Bread",
"Fudge",
"Fudgy",
"Farms",
"Serve",
"Tarts",
"Local",
"Treat",
"Slice",
"Piece",
"Cream",
"Frost",
"Crisp",
"Layer",
"Dough",
"Happy",
"Warms",
"Bliss"];
